import * as React from 'react';
import styled from 'react-emotion';

import { breakpoints, colors } from '../styles/variables';

const TileContainer = styled.div`
	background-color: ${colors.dark};
	border-radius: 12px;
	padding: 15px;
	margin: 20px;

	@media only screen and (max-width: ${breakpoints.sm}px) {
		padding: 5px;
	}
`;

interface TileProps {
	children: React.ReactNode;
}

const Tile: React.FunctionComponent<TileProps> = ({ children }) => {
	return <TileContainer>{children}</TileContainer>;
};

export default Tile;
