/* tslint:disable:ter-max-len */
import * as React from 'react';
import { get } from 'lodash';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'react-emotion';

import Container from '../components/Container';
import IndexLayout from '../layouts';
import Page from '../components/Page';
import Tile from '../components/Tile';

import { fluidImage as fluidImageFragment } from '../queries/fragments';

import { breakpoints, colors, fonts } from '../styles/variables';
import { H1 } from '../styles/components/_h1';

import { useContainerWidth } from '../util/hooks';

const Author = styled.div`
	color: ${colors.light};
	font-size: 22px;
	font-family: ${fonts.sansSerif};
	font-weight: 400;
`;

const Content = styled.div`
	justify-content: flex-start;
	padding: 15px;
`;

const Header = styled.div`
	padding: 10px 15px 20px 15px;
`;

interface ImageProps {
	width: number;
}

const ImageContainer = styled.div`
	float: left;
	display: inline;
	height: auto;
	margin-bottom: 10px;
	margin-right: 25px;
	max-width: 300px;
	width: ${({ width }: ImageProps) => width}px;
`;

const Intro = styled.div`
	margin: 20px;
	padding-top: 18px;

	@media only screen and (max-width: ${breakpoints.sm}px) {
		padding-top: 25px;
	}
`;

const P = styled.p`
	color: ${colors.light};
	display: block;
	font-size: 16px;
	padding: 0 10px 0 0;
`;

const Note = styled(P)`
	color: ${colors.accent};
`;

const Selection = styled.div``;

const Spacer = styled.div`
	height: 10px;
`;

const Time = styled.div`
	color: ${colors.lightAccent};
	line-height: 20px;
`;

const Title = styled(H1)`
	margin-left: -3px;
	padding: 0;
`;

interface BookClubProps {
	data?: any;
}

const BookClub: React.FunctionComponent<BookClubProps> = ({ data }) => {
	const outlineFluid = get(data, 'outline.childImageSharp.fluid');
	const id = 'bookcontent';
	const containerWidth = useContainerWidth(`#${id}`, data);
	return (
		<IndexLayout>
			<Page>
				<Container>
					{(containerWidth || 0) < breakpoints.md && <Spacer />}
					<Intro>
						<P>
							I love to talk about books, particularly fiction, but I seldom get the
							opportunity to do so. I'm not a billionaire or a former president. I
							don't have a column or a show or a published novel, at least not yet.
							I've been in science and engineering since I was 19, surrounded mostly
							by other scientists and engineers. But I'm also Book People.
						</P>
						<P>
							Through the years, I've encountered or known many other Book People, and
							I've always enjoyed our conversations. When I see Book People out in the
							world, carrying their telltale books, I wish I could initiate a
							conversation, but I don't want to interrupt. I also know, from the other
							side of that experience, that sometimes a book is so good you wish
							people would ask about it. In fact you are dying to talk about it and
							are a few random neural firings away from standing up and sermonizing to
							the subway car. In that spirit, but from the safety of my own website, I
							bring you my book club.
						</P>
					</Intro>
					<Spacer />
					<Tile>
						<Selection>
							<Header>
								<Title>Outline</Title>
								<Author>Rachel Cusk</Author>
								<Time>January 9, 2018</Time>
							</Header>
							<Content id={id}>
								<ImageContainer width={0.8 * Number(containerWidth) || 300}>
									<a
										href={`https://www.amazon.com/Outline-Novel-Trilogy-Rachel-Cusk/dp/1250081548/ref=sr_1_1?ie=UTF8&qid=1546829061&sr=8-1&keywords=outline+by+rachel+cusk`}
										rel="noopener noreferrer"
										target="_blank"
									>
										<Img fluid={outlineFluid} />
									</a>
								</ImageContainer>
								<P>
									This is the book that made me want to start a book club. It sat
									in my wish list for a couple of years before reviews of the
									sequel reminded me of it. I regretted the delay. Anyone around
									me while I was reading it in early 2017 heard about this book.
									My subway rides and lunch breaks were ecstatic. I was tingly
									with love. About six months later, Rachel Cusk was profiled in
									The New Yorker under the headline "Rachel Cusk gut-renovates the
									novel", which is a fitting description. There has been a
									discussion in literary circles about autofiction as the new
									trend in the evolution of the novel, led by Karl Ove Knausgaard
									and his hyperrealistic My Struggle series, along with Ben
									Lerner, Sheila Heti, and, depending on your definitions, Elena
									Ferrante. But Cusk seems to have struck out in her own
									direction—it's tough to even characterize what it is she's
									doing.
								</P>

								<P>
									While I've seen echoes of this before—the strangers unburdening
									themselves to the narrator who's trying to get to his piano
									performance in Kazuo Ishiguro's The Unconsoled, the dialogue
									across the years in W.G. Sebald's Austerlitz—I couldn't shake
									the feeling while reading Outline that Cusk was indeed creating
									something foundational.
								</P>
								<P>
									The plot is simple. A woman goes to Athens to teach a summer
									writing course, and she converses with various people: her
									seatmate on the flight, a colleague, her pupils, a friend in the
									city. The others do almost all of the talking, long, revelatory,
									thought-provoking monologues. Every page or two there is some
									wonderful image or comparison or phrasing, it's fully absorbing.
									We gather that the narrator, Faye, is divorced. Sometimes one of
									her kids calls and she switches modes. But we don't learn much
									else about her directly. Instead, she becomes this vessel for
									other people to express themselves, and we only see her in
									outline, hence the title. What's interesting and makes this
									capital-A Art is the way this theme is threaded between all the
									conversations, which are various meditations on relationships
									and how we are defined by other people.
								</P>
								<P>
									Transit and Kudos, the sequels, are fantastic as well. Kudos,
									which Cusk signed for me at an event, is particularly biting,
									and ends the series on a high note, with a potent and
									unforgettable image of the misogyny of our age.
								</P>
								<P>
									Outline was one of several books that inspired me to start
									writing again, so it predated my own project. But I read the
									sequels while in the midst of it. They all lit a jealous fire in
									me. How did she do whatever it was she was doing? This feeling
									kept me focused though, because seeking to imitate her would be
									folly. Cusk is operating on her own level.
								</P>
							</Content>
						</Selection>
					</Tile>
					<Note>
						Note: Although I work at Amazon and link to their product page, I also
						frequently support local bookstores and encourage you to do the same.
					</Note>
				</Container>
			</Page>
		</IndexLayout>
	);
};

export default BookClub;

export const fluidImage = fluidImageFragment;
export const pageQuery = graphql`
	query {
		outline: file(relativePath: { regex: "/books/.*Outline.jpg/" }) {
			...fluidImage
		}
	}
`;
